<template>
  <FloatLabel variant="on">
    <Select
      v-model="selectedTax"
      data-testid="freight-tax"
      :options="taxes"
      option-label="taxName"
      class="inputfield w-full"
      pt:list:data-testid="freight-tax-list"
    >
      <template #value="{ value, placeholder }">
        <template v-if="value"
          >{{ value.taxName }} - {{ value.rate }}%{{
            value.isDefaultTaxForFreight ? " (" + t("freight.default") + ")" : ""
          }}</template
        >
        <template v-else>{{ placeholder }}</template>
      </template>
      <template #option="{ option }"
        >{{ option.taxName }} - {{ option.rate }}%{{
          option.isDefaultTaxForFreight ? " (" + t("freight.default") + ")" : ""
        }}</template
      >
    </Select>
    <label for="freight-tax" class="font-bold text-sm">
      {{ t(`freight.tax`) }}
    </label>
  </FloatLabel>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { FreightMethodTax } from "@/repositories/freight-method/model/FreightMethodTax";
import { useTax } from "@/repositories/tax/TaxService";
import { Tax } from "@/repositories/tax/model/Tax";
import Select from "primevue/select";

const { t } = useI18n();
const { getOutgoingTaxesForCountry } = useTax();

const props = defineProps<{
  tax: FreightMethodTax;
}>();

const emit = defineEmits<{
  (e: "update:tax", value: FreightMethodTax): void;
  (e: "setInitialTax", value: FreightMethodTax): void;
}>();

const taxes = ref<Tax[]>([]);

const selectedTax = computed<Tax>({
  get: (): Tax => {
    return getTaxById(props.tax?.taxId) ?? getDefaultTax() ?? taxes.value[0];
  },
  set: (value: Tax | null) => {
    if (!value) return;

    emit("update:tax", new FreightMethodTax(value.id, value.rate));
  },
});

const getDefaultTax = (): Tax | null => {
  return taxes.value?.find((tax) => tax.isDefaultTaxForFreight) ?? null;
};

const getTaxById = (id: string): Tax | null => {
  return taxes.value?.find((tax) => tax.id === id) ?? null;
};

const fetchTaxes = async () => {
  taxes.value = (await getOutgoingTaxesForCountry("NO")).sort((a, b) => a.rate - b.rate);

  if (taxes.value.length === 0) {
    return;
  }

  if ((props.tax?.taxId?.length ?? 0) === 0) {
    const defaultTax = getDefaultTax() ?? taxes.value[0];
    emit("setInitialTax", new FreightMethodTax(defaultTax.id, defaultTax.rate));
  }
};
onMounted(fetchTaxes);
</script>
