<template>
  <div id="bring-service-agreements">
    <DataTable
      :value="agreements"
      data-key="id"
      :loading="loadingAgreements"
      striped-rows
      class="c-datatable"
      @row-dblclick="onRowDblClick"
    >
      <Column :header="t('freight.name')" field="name" />
      <Column :header="t('freight.customer-number')" field="customerNumber" />
      <Column :header="t('freight.client-url')" field="clientURL" />
    </DataTable>
  </div>
</template>
<script setup lang="ts">
import { ref, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { type DataTableRowDoubleClickEvent } from "primevue/datatable";
import { useBringServiceAgreements } from "@/bring-service-agreements/BringServiceAgreementService";
import { BringServiceAgreement } from "../model/BringServiceAgreement";

const loadingAgreements = ref(true);
const agreements = ref();
const { t } = useI18n();

const { getAllServiceAgreements } = useBringServiceAgreements();

const emit = defineEmits<{
  (e: "openUpdateModal", value: BringServiceAgreement): void;
}>();

const onRowDblClick = (event: DataTableRowDoubleClickEvent) => {
  emit("openUpdateModal", event.data);
};

onMounted(async () => {
  loadingAgreements.value = true;
  try {
    agreements.value = await getAllServiceAgreements();
  } finally {
    loadingAgreements.value = false;
  }
});
</script>
