<template>
  <div class="c-layout-content">
    <ConfirmPopup />
    <CustomToast />
    <PrimeVueLocaleHandler />
    <router-view v-slot="{ Component }">
      <keep-alive include="freight">
        <component :is="Component" :key="$route.fullPath" />
      </keep-alive>
    </router-view>
    <DevOverlay v-if="isStandalone && !isRunningTests" />
  </div>
</template>

<script setup lang="ts">
import { PrimeVueLocaleHandler } from "@cumulus/locale";
import { CustomToast } from "@cumulus/components";
import { onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useAuth } from "@cumulus/event-bus";
import { DevOverlay } from "@cumulus/components";

const { t } = useI18n();
const router = useRouter();
const isStandalone = ref(import.meta.env.VITE_APP_STANDALONE === "true");
const isRunningTests = ref(import.meta.env.VITE_APP_TESTS === "true");

router.beforeEach((to, _, next) => {
  if (to.meta?.title) {
    document.title = t(to.meta?.title as string);
  }
  next();
});

if (isStandalone.value) {
  const { getUser, isAuthenticated } = useAuth();
  onMounted(async () => (document.body.className = (await getUser()).getBodyCssClass(isAuthenticated.value)));
}
</script>
