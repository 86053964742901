<template>
  <FloatLabel variant="on">
    <InputText
      id="freight-carrier"
      v-model="carrier"
      v-tooltip.focus.bottom="{
        value: t('placeholder.type', { property: t('freight.carrier').toLowerCase() }),
        showDelay: 1000,
        hideDelay: 300,
      }"
      type="text"
      data-testid="freight-carrier"
      class="inputfield w-full"
      :class="{ 'p-invalid': val.carrier.$error }"
    />
    <label for="freight-carrier" class="font-bold text-sm">
      {{ t(`freight.carrier`) }}
    </label>
  </FloatLabel>
  <small v-if="val.carrier.$error" id="freight-carrier-help" data-testid="freight-carrier-error" class="p-error">{{
    val.carrier.$errors[0].$message
  }}</small>
</template>

<script setup lang="ts">
import { useVuelidate } from "@vuelidate/core";
import { required } from "@/locales/i18n-validators";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const carrier = defineModel<string>("carrier", {
  required: true,
});

const rules = {
  carrier: {
    required: required,
  },
};

const val = useVuelidate(rules, { carrier: carrier });
</script>
